.project-preview {
    border-radius: 12px;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    margin: 0 auto;

}

.project-preview:hover {
    transform: scale(1.05);
}

.project-preview-image {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.project-preview-title {
    font-size: 24px;
    margin: 16px 0 12px;
    color: #333;

    text-decoration: none;
}

.project-preview-description {
    color: #555;
    font-size: 16px;
    line-height: 1.6;

    text-decoration: none;
}