html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  color: white;
  font-size: 1.5rem;

  background-color: black;

  scroll-behavior: smooth;

}

.default-container {
  padding: 0 5vw;
}

@media (min-width: 768px) {
  .default-container {
    padding: 10vh 25vw;
  }
}

h1 {
  font-size: 4rem;
}

.spherical-gradient {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  background: var(--gradient1), var(--gradient2);
  background-size: 500px 500px;
  filter: blur(80px);
  mix-blend-mode: screen;
  width: 500px;
  height: 500px;
  transition: background 5s ease-in-out;
}