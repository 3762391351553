.navbar {
    padding: 1rem;

}

.navbar-links {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.navbar-links a:hover {
    color: #007bff;
}