.project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.project-grid-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.project-grid-item:hover {
    transform: scale(1.02);
}

.project-image {
    width: 100%;
    height: auto;
}

.project-info {
    padding: 15px;
    text-align: center;
}

.project-info h3 {
    margin: 0 0 10px;
    font-size: 18px;
}

.project-info p {
    margin: 0;
    color: #555;
}

.project-grid-item a {
    text-decoration: none !important;
    color: inherit !important;
}

.project-info p {
    text-decoration: none !important;
    cursor: default;
    font-weight: 400 !important;
}