@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

.roboto-regular {
    font-family: "Roboto", sans-serif;
    /* Specify the font family */
    font-weight: 400;
    /* Regular weight */
    font-style: normal;
    /* Normal style */
}

.project-page-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    color: black;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 40px
}

.project-title {
    text-align: left;
}

.project-subtitle {
    color: grey;
    margin-top: 5px;
    font-size: 1.2em;
}


.header-image img {
    width: 100%;
    height: auto;
}

.content-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

}