.home-container {
    padding: 0 5vw;
}

@media (min-width: 768px) {
    .home-container {
        padding: 10vh 25vw;
    }
}

.action-button {
    margin-top: 20px;

}

.action-button button {
    background: transparent;
    color: #fff;
    border: 2px solid #007bff;
    padding: 12px 30px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    transition: color 0.4s ease;
}

.action-button button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #007bff;
    transition: left 0.4s ease;
    z-index: 0;
}

.action-button button:hover::before {
    left: 0;
}

.action-button button:hover {
    color: #fff;
    border-color: #007bff;
}

.action-button button span {
    position: relative;
    z-index: 1;
}